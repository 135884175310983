import { NAME } from '@/app/campaigns/constants';

import { useTranslation } from 'next-i18next';

import { CopyLinkButton } from '@/app/campaigns/components/Overview/CopyLinkButton';
import {
    getCampaignIsLive,
    getCampaignIsArchived,
    getCampaignUrl,
    getCampaignStatus,
    getCampaignTrackingVersion,
    getCampaignContactCount,
} from '@/app/campaigns/helpers';
import { CapabilitiesTarget, useUserCapabilities } from '@/utils/hooks/useUserCapabilities';

import KpiBadge from './KpiBadge';
import StatusBadge from './StatusBadge';
import MoreMenu from '../../../MoreMenu';

import type { CampaignResource, CampaignStatus } from '@/app/campaigns/types';

export interface Props {
    campaign: CampaignResource;
    onMoreMenuOpenChange?: (open: boolean) => void;
}

const Footer = ({ campaign, onMoreMenuOpenChange }: Props) => {
    const { t } = useTranslation(NAME);
    const url = getCampaignUrl(campaign);
    const contactCount = getCampaignContactCount(campaign);
    const isV3Funnel = getCampaignTrackingVersion(campaign) === 'v3';
    const { canUpdate } = useUserCapabilities(CapabilitiesTarget.Campaign);

    return (
        <div className="flex justify-between">
            <div className="flex gap-2">
                {getCampaignIsLive(campaign) && !getCampaignIsArchived(campaign) && (
                    <CopyLinkButton link={url} tooltipPlacement="top-start" />
                )}
                <StatusBadge
                    status={getCampaignStatus(campaign) as CampaignStatus}
                    archived={getCampaignIsArchived(campaign)}
                />
                {getCampaignIsLive(campaign) && !getCampaignIsArchived(campaign) && (
                    <KpiBadge
                        isV3Funnel={isV3Funnel}
                        campaignId={campaign.id}
                        number={contactCount}
                        text={contactCount === 1 ? t('contact') : t('contacts')}
                    />
                )}
            </div>
            {canUpdate && <MoreMenu campaign={campaign} onOpenChange={onMoreMenuOpenChange} />}
        </div>
    );
};

export default Footer;
