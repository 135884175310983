import { cn } from '@/utils/cn';

import CreateWorkspaceButton from './CreateWorkspaceButton';
import WorkspaceLinks from './WorkspaceLinks';

interface Props {
    showWorkspaceLinks: boolean;
    showWorkspaceAddButton: boolean;
}

const WorkspaceSidebar = ({ showWorkspaceLinks, showWorkspaceAddButton }: Props) => {
    return (
        <div
            className={cn(
                'absolute left-0 top-[64px] z-50 flex w-16 flex-col items-center gap-3 px-3 pb-3',
            )}
        >
            {showWorkspaceLinks && <WorkspaceLinks />}

            {showWorkspaceAddButton && <CreateWorkspaceButton />}
        </div>
    );
};

export default WorkspaceSidebar;
