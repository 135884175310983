import { LS_LAST_ACTIVE_WORKSPACE_ID_KEY } from '@/app/workspaces/constants';

import Head from 'next/head';
import { useEffect } from 'react';

import Overview from '@/app/campaigns/components/Overview';
import TopBar from '@/app/campaigns/components/Overview/TopBar';
import V3SharingWarningBanner from '@/app/campaigns/components/Overview/V3SharingWarningBanner';
import { reset as resetWorkspaceStatus } from '@/app/crm/models/workspaceStatus';
import { getWorkspaceById, setActiveWorkspaceId } from '@/app/workspaces/models/workspaces';
import { useNavigationUpdatesEnabled } from '@/core/loli-feature-flags/features/navigationUpdates';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { cn } from '@/utils/cn';
import { getIsWorkspaceEnv } from '@/utils/environments';
import { CapabilitiesTarget } from '@/utils/hooks/useUserCapabilities';
import { useUserCapabilities } from '@/utils/hooks/useUserCapabilities';

import WorkspaceSidebar from '../WorkspaceSidebar';

interface Props {
    id: string;
}

const Workspace = ({ id }: Props) => {
    const dispatch = useAppDispatch();

    const navbarUpdatesFeature = useNavigationUpdatesEnabled();
    const workspaceCapability = useUserCapabilities(CapabilitiesTarget.Workspaces);

    const workspace = useAppSelector((state) => getWorkspaceById(state, id));

    useEffect(() => {
        if (id) {
            dispatch(setActiveWorkspaceId(id));

            // We store the last active workspace ID and default flag
            // to correctly navigate on "Funnels" click with the new navigation bar.
            // When clicking "Funnels", the user shall be redirected back to the funnel
            // overview page of the last active workspace.
            localStorage.setItem(LS_LAST_ACTIVE_WORKSPACE_ID_KEY, id);
        }

        // store the id in localStorage so that we can easily navigate between academy and referrals and performance pages back to Funnels
    }, [dispatch, id, workspaceCapability.canUpdate, workspace]);

    // Makes sure workspace status are clean when loading the workspace
    useEffect(() => {
        dispatch(resetWorkspaceStatus());
    }, [dispatch, id]);

    const title = getIsWorkspaceEnv() ? 'Workspace Funnels' : 'Perspective Funnels';
    const areNavigationUpdatesEnabled = useNavigationUpdatesEnabled();

    return (
        <>
            <Head>
                <title>{title}</title>
            </Head>

            <div
                className={cn('mx-auto w-[1152px] max-w-full px-4 pb-24', {
                    'pl-18 xl:pl-4': !areNavigationUpdatesEnabled,
                    'w-full max-w-6xl pb-16': areNavigationUpdatesEnabled,
                })}
            >
                {/* If the new default workspace feature is enabled, we want to hide the old workspace switcher/navigation. */}
                {!navbarUpdatesFeature && (
                    <WorkspaceSidebar
                        showWorkspaceLinks={workspaceCapability.canRead}
                        showWorkspaceAddButton={workspaceCapability.canCreate}
                    />
                )}

                <TopBar />
                <V3SharingWarningBanner workspaceId={id} />
                <Overview workspaceId={id} />
            </div>
        </>
    );
};

export default Workspace;
