import { memo, useMemo } from 'react';

import { useWorkspaces } from '@/app/workspaces/hooks/useWorkspaces';
import { getIsPreview } from '@/app/workspaces/models/workspaces';
import { useAppSelector } from '@/core/redux/hooks';
import { getIsWorkspaceEnv } from '@/utils/environments';
import { CapabilitiesTarget, useUserCapabilities } from '@/utils/hooks/useUserCapabilities';

import SortableWorkspaceLinks from './SortableWorkspaceLinks';
import WorkspaceButton from './WorkspaceButton';

const WorkspaceLinks = () => {
    const {
        activeWorkspace,
        allWorkspaces,
        workspaceLimit,
        nonActiveCustomWorkspaces,
        customWorkspaces,
    } = useWorkspaces();
    const isPreview = useAppSelector(getIsPreview);

    const { canCreate } = useUserCapabilities(CapabilitiesTarget.Workspaces);
    const isWorkspaceEnv = getIsWorkspaceEnv();
    const omitActiveWorkspaceButton = isWorkspaceEnv || !canCreate;

    const workspaceIds = useMemo(() => {
        if (isPreview) {
            return [];
        }

        if (omitActiveWorkspaceButton) {
            return Object.keys(nonActiveCustomWorkspaces);
        }

        return Object.keys(customWorkspaces);
    }, [isPreview, omitActiveWorkspaceButton, customWorkspaces, nonActiveCustomWorkspaces]);

    const workspaceIdsWithLimit = workspaceIds?.slice(0, workspaceLimit - 1); // -1 to offset the default workspace

    if (!canCreate) {
        return (
            <>
                {workspaceIdsWithLimit?.map((workspaceId) => (
                    <WorkspaceButton
                        key={workspaceId}
                        id={workspaceId}
                        active={activeWorkspace?.id === workspaceId}
                        linkToDomain={isWorkspaceEnv}
                        {...allWorkspaces[workspaceId]?.attributes}
                    />
                ))}
            </>
        );
    }

    return <SortableWorkspaceLinks workspaceIds={workspaceIdsWithLimit} />;
};

export default memo(WorkspaceLinks);
