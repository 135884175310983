import { NAME } from '@/app/workspaces/constants';

import { PlusIcon } from '@radix-ui/react-icons';
import { useTranslation } from 'react-i18next';

import { useWorkspaces } from '@/app/workspaces/hooks/useWorkspaces';
import { navigateToAddNewWorkspacePage } from '@/app/workspaces/models/workspaces';
import { useAppDispatch } from '@/core/redux/hooks';
import Tooltip from '@/ui/components/Tooltip';

const CreateWorkspaceButton = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation(NAME);

    const { isAllowedToHaveCustomWorkspaces, hasExceededWorkspaceLimit } = useWorkspaces();

    const canAddNewWorkspace = isAllowedToHaveCustomWorkspaces && !hasExceededWorkspaceLimit;

    const handleClick = () => {
        dispatch(navigateToAddNewWorkspacePage(canAddNewWorkspace));
    };

    return (
        <Tooltip content={t('add-workspace')}>
            <button
                className="flex size-10 shrink-0 items-center justify-center rounded-md text-gray-500 transition-all duration-150 hover:bg-gray-100 hover:text-blue-500"
                onClick={handleClick}
            >
                <PlusIcon width={20} height={20} />
            </button>
        </Tooltip>
    );
};

export default CreateWorkspaceButton;
