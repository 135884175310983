import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import WorkspaceButton from './WorkspaceButton';

import type { Props as WorkspaceButtonProps } from './WorkspaceButton';

interface Props extends WorkspaceButtonProps {}

export const SortableWorkspaceButton = (props: Props) => {
    const { attributes, listeners, setNodeRef, transform, isDragging, transition } = useSortable({
        id: props.id,
    });

    return (
        <WorkspaceButton
            {...props}
            style={{
                transition,
                transform: CSS.Translate.toString(transform),
                opacity: isDragging ? 0 : 1,
            }}
            ref={setNodeRef}
            {...attributes}
            {...listeners}
        />
    );
};

export default SortableWorkspaceButton;
