import { closestCenter } from '@dnd-kit/core';
import { useSensor, useSensors } from '@dnd-kit/core';
import { DragOverlay, PointerSensor } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { verticalListSortingStrategy } from '@dnd-kit/sortable';
import { SortableContext } from '@dnd-kit/sortable';
import { useCallback, useState } from 'react';
import { memo } from 'react';
import { createPortal } from 'react-dom';

import { useWorkspaces } from '@/app/workspaces/hooks/useWorkspaces';
import { updateOrder } from '@/app/workspaces/models/workspaces';
import { useAppDispatch } from '@/core/redux/hooks';
import { getIsWorkspaceEnv } from '@/utils/environments';

import SortableWorkspaceButton from './SortableWorkspaceButton';

import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core';

interface Props {
    workspaceIds: string[];
}

const SortableWorkspaceLinks = ({ workspaceIds }: Props) => {
    const { allWorkspaces, workspaceOrder, activeWorkspace } = useWorkspaces();
    const activeWorkspaceId = activeWorkspace?.id;

    const [active, setActive] = useState<string>();
    const dispatch = useAppDispatch();

    const isWorkspaceEnv = getIsWorkspaceEnv();

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        }),
    );

    const handleDragStart = useCallback(({ active }: DragStartEvent) => {
        setActive(active.id as string);
    }, []);

    const update = useCallback(
        async (activeIndex: number, overIndex: number) => {
            dispatch(updateOrder(activeIndex, overIndex));
            setActive(undefined);
        },
        [dispatch],
    );

    const handleDragEnd = useCallback(
        ({ over, active }: DragEndEvent) => {
            const overId = over?.id as string;
            const activeId = active?.id as string;

            if (!overId || !activeId) {
                return;
            }

            if (overId === activeId) {
                return;
            }

            const activeIndex = workspaceOrder.indexOf(activeId);
            const overIndex = workspaceOrder.indexOf(overId);

            if (activeIndex >= 0 && overIndex >= 0) {
                update(activeIndex, overIndex);
            }
        },
        [workspaceOrder, update],
    );

    const handleDragCancel = useCallback(() => {
        setActive(undefined);
    }, []);

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
            modifiers={[restrictToVerticalAxis]}
        >
            <SortableContext items={workspaceIds} strategy={verticalListSortingStrategy}>
                {workspaceIds.map((workspaceId) => (
                    <SortableWorkspaceButton
                        key={workspaceId}
                        id={workspaceId}
                        active={activeWorkspaceId === workspaceId}
                        linkToDomain={isWorkspaceEnv}
                        {...allWorkspaces[workspaceId]?.attributes}
                    />
                ))}
            </SortableContext>

            {createPortal(
                <DragOverlay
                    dropAnimation={{
                        duration: 60,
                        easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
                    }}
                >
                    {active ? (
                        <div className="w-72">
                            <SortableWorkspaceButton
                                id={active}
                                {...allWorkspaces[active]?.attributes}
                                showTooltip={false}
                            />
                        </div>
                    ) : null}
                </DragOverlay>,
                document.body,
            )}
        </DndContext>
    );
};

export default memo(SortableWorkspaceLinks);
